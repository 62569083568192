<template>
  <div class="payment-method">
    <GooCreditCardLogo :type="paymentMethod.brand" />

    <div class="payment-method-details">
      <div class="has-text-bold">
        Card ending in {{ paymentMethod.lastDigits }}
      </div>
      <div class="has-text-gray">
        Expires  {{ paymentMethod.expMonth }} / {{ paymentMethod.expYear }}
      </div>
    </div>
  </div>
</template>

<script>
  import GooCreditCardLogo from "@/goo2/components/goo-credit-card-logo/GooCreditCardLogo";

  export default {
    name: "PaymentMethod",
    components: { GooCreditCardLogo },
    props: {
      paymentMethod: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss">
  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-details {
      flex: 1;
      margin: 0 16px;
    }
  }
</style>
