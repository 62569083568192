<template>
  <button class="responsive-edit-button" v-on="$listeners">
    <goo-icon name="pencil" class="responsive-edit-button-icon" />
    <span class="responsive-edit-button-label"><slot /></span>
  </button>
</template>

<script>
  export default {
    name: "ResponsiveEditButton"
  };
</script>

<style lang="scss">
  @import "src/css/theme";

  .responsive-edit-button {
    @extend .goo-button;
    @extend .is-outlined;
    @extend .is-primary;

    &-icon { display: none!important; }
    &-label { display: inline; }

    @media only screen and (max-width: 1000px) {
      min-width: 0;
      padding: 7px;

      &-icon { display: inline-block!important; }
      &-label { display: none; }
    }
  }
</style>
